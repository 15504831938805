import { Ref } from 'vue'
import useFetch from '@/utils/fetch'
import type { AccountProperties } from './models'

const consifurationsBffPath = String(process.env.VUE_APP_CONFIGURATIONS_BFF)
const accountPath = `${consifurationsBffPath}/account`

async function changeProperties (body: AccountProperties): Promise<Ref<AccountProperties>> {
  const response = await useFetch(`${accountPath}/edit/properties`).put(body).json()
  return response.data
}

export {
  changeProperties
}
