import 'systemjs-webpack-interop/auto-public-path/auto-public-path'
import { h, createApp } from 'vue'
import money from 'v-money3'
import singleSpaVue from 'single-spa-vue'
import '@/tailwind.css'
import 'element-plus/dist/index.css'

import App from './App.vue'
import pinia from './stores'
import router from './router'
import i18n from './locales'
import { vMaska } from 'maska'
import amplitude from './composables/amplitude'

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render () {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
      })
    }
  },
  handleInstance (app) {
    app
      .use(pinia)
      .use(router)
      .use(i18n)
      .use(money)
      .use(amplitude)
      // .use(ElementPlus)
      .directive('maska', vMaska)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
