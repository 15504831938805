import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { AccountProperties, Account } from './models'

import * as api from './integrations'

const useAccountStore = defineStore('accountStore', () => {
  const account = ref<Account>(JSON.parse(localStorage.getItem('jus_account') ?? '{}'))
  const getProperties = computed((): AccountProperties => account.value.properties)
  const getAccountName = computed((): string => account.value?.name ?? '')
  const getAccountEmail = computed((): string => account.value?.email ?? '')

  async function changeProperties (properties: AccountProperties): Promise<void> {
    const body = properties
    const data = await api.changeProperties(body)
    localStorage.setItem('jus_account', JSON.stringify({
      ...account.value,
      properties: data.value
    }))
    account.value.properties = data.value
  }

  return {
    getProperties,
    getAccountName,
    getAccountEmail,
    changeProperties
  }
})

export default useAccountStore
