import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { TempPerson } from './model'

const usePersonStore = defineStore('personStore', () => {
  const personData = ref(JSON.parse(localStorage.getItem('jusperson') ?? '{}'))
  const tempPersonData = ref<TempPerson | undefined>()
  const getPersonId = computed((): number => personData.value.id)
  const getTempPersonData = computed((): TempPerson | undefined => tempPersonData.value)
  const getTempFilterIsToday = computed(() => {
    if (tempPersonData?.value != null) {
      return tempPersonData.value.data.split('T')[0] === new Date().toISOString().split('T')[0]
    }

    return true
  })

  function setTempPersonData (data: TempPerson | undefined): void {
    tempPersonData.value = data
  }

  return {
    getPersonId,
    getTempPersonData,
    setTempPersonData,
    getTempFilterIsToday
  }
})

export default usePersonStore
